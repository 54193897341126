.navActionRotate{
    transform: rotate(180deg);
}

.navButton{
    padding: 5px;
}

.navButtonActive{
    text-decoration: overline;
}

.navButton:hover{
    text-shadow: 0 2px 10px rgba(255, 254, 254, 0.541);
    text-decoration: overline;
}

.Header, .HeaderHome{
    position: fixed;
    top: 0;
    background-color: rgb(29, 10, 54);
    box-shadow: 0 -15px 5px 20px rgb(29, 10, 54);
}

.lang-selector{
    padding: .4em .6em .4em .6em;
    border:1px solid rgba(255, 255, 255, 0.18);
    box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37);
    border-radius: 10px;
}

.lang-selector:hover{
    border-color: rgb(181, 181, 228);
    box-shadow: 0 0 15px rgba(255, 255, 255, 0.18);
}

