@tailwind base;
@tailwind components;
@tailwind utilities;

html, body, #root, .page {
  height: 100%;
  /*background-color: rgb(16, 0, 26);*/
  background-image: url('./components/svg/bg.svg');
  /*background-size: 100%*/
  background-repeat: repeat;
} 

html {
  cursor: url('./components/img/cursor.png'),none;
}

a, .arrowdiv {
  cursor: url('./components/img/cursor_select.png'),default;
}

#PageBody {
  opacity: 0;
  animation: fadeInAnimation ease 1s;
  animation-delay: 150ms;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}

::-webkit-scrollbar {
  width: 0px;
}
