.Card {
    background-color: aliceblue;
    border-radius: 10px;
    color: white;
}

.Card:hover {
    border-color: rgb(181, 181, 228);
}

.selected-card {
    border-color: rgb(181, 181, 228) !important;
}

.glass{
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0));
    -webkit-backdrop-filter: blur(10px);
    border:1px solid rgba(255, 255, 255, 0.18);
    border-radius: 10px;
}